const initState = {
    map_data: {}
}

export const reducer = (globalState = initState, action) => {
    switch (action.type) {
        case "SET_MAPDATA": {
            return {
                ...globalState,
                map_data:action.payload
            }
        }
        default:
          return globalState
    }
}

export default reducer