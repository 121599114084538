import React from 'react';
import { 
  BrowserRouter as Router, 
  Route, 
  Switch
} from 'react-router-dom';
import 'video-react/dist/video-react.css';
import './App.scss';

const LandingPage = React.lazy(() => import('./Pages/LandingPage'));
const MapsPage = React.lazy(() => import('./Pages/MapsPage'));
const AboutUsPage = React.lazy(() => import('./Pages/AboutUsPage'));
const MendAThonPage = React.lazy(() => import('./Pages/MendAThonPage'));
const SearchPage = React.lazy(() => import('./Pages/SearchPage'));

const loading = () => <div className="animated fadeIn pt-3 text-center loading-message">Loading...</div>;

function App(props) {

  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          <Route exact path="/maps" name="Maps page" render={props => <MapsPage {...props}/>} />
          <Route exact path="/about-us" name="About us page" render={props => <AboutUsPage {...props}/>} />
          <Route exact path="/mendathon" name="Mend A Thon page" render={props => <MendAThonPage {...props}/>} />
          <Route exact path="/search" name="Search page" render={props => <SearchPage {...props}/>} />
          <Route exact path="/:id" name="Home" render={props => <LandingPage {...props}/>} />
          <Route exact path="/" name="Home" render={props => <LandingPage {...props}/>} />
        </Switch>
      </React.Suspense>
    </Router>
  );
}

export default App;
